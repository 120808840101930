.delete-button {
    background-color: red;
    color: white;
    border: none;
    border-radius: 4px;
    width: 20px;
    height: 20px;
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0px 10px;
  }
  