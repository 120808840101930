.custom-select {
  position: relative;
  display: block;
  max-width: 600px;
  margin: 0 auto;
}

.custom-select select {
  display: block;
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  text-align-last: center;
  text-align: left; /* For Firefox */
}

/* For other browsers */
.custom-select select option {
  text-align: left;
}

.custom-select::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 15px;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid white;
  transform: translateY(-50%);
  pointer-events: none;
}
