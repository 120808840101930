body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px 0;
  width: 100%;
}

.App-header h1 {
  color: white;
}

.dropzone {
  padding: 30px;
  border: 2px dashed #007bff;
  border-radius: 5px;
  background-color: white;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  max-width: 600px;
}

.file-list {
  max-width: 600px;
  margin: 40px auto;
  margin-bottom: 20px;
  background-color: #282c34;
  border-radius: 5px;
  padding: 20px;
  font-family: 'Courier New', Courier, monospace;
  max-height: 400px;
  overflow-y: auto;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.file-list h2 {
  margin: 0;
  margin-bottom: 10px;
  font-size: 1.2rem;
  color: #ffffff;
}

.file-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.file-list li {
  display: flex;
  align-items: center;
  color: #ffffff;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow-x: auto;
  background: #4a4f58;
  border-radius: 5px;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}


button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
